import { allDistrictsConstants, allDistrictsDataConstants } from "../constants";

export function districtsReducer(
  state = {
    allDistrictsLoading: false,
    allDistrictsData: null,
    allDistrictsError: null,

    allDistrictDataLoading: false,
    allDistrictData: null,
    allDistrictDataError: null,
  },
  action
) {
  switch (action.type) {
    case allDistrictsConstants.ALL_DISTRICTS_LOADING:
      return Object.assign({}, state, {
        allDistrictsLoading: true,
        allDistrictsData: null,
        allDistrictsError: null,
      });
    case allDistrictsConstants.ALL_DISTRICTS_SUCCESS:
      return Object.assign({}, state, {
        allDistrictsLoading: false,
        allDistrictsData: action.data,
      });
    case allDistrictsConstants.ALL_DISTRICTS_FAILURE:
      return Object.assign({}, state, {
        allDistrictsLoading: false,
        allDistrictsData: null,
        allDistrictsError: action.error,
      });

    case allDistrictsDataConstants.ALL_DISTRICTS_DATA_LOADING:
      return Object.assign({}, state, {
        allDistrictDataLoading: true,
        allDistrictData: null,
        allDistrictDataError: null,
      });
    case allDistrictsDataConstants.ALL_DISTRICTS_DATA_SUCCESS:
      return Object.assign({}, state, {
        allDistrictDataLoading: false,
        allDistrictData: action.data,
      });
    case allDistrictsDataConstants.ALL_DISTRICTS_DATA_FAILURE:
      return Object.assign({}, state, {
        allDistrictDataLoading: false,
        allDistrictData: null,
        allDistrictDataError: action.error,
      });
    default:
      return state;
  }
}
