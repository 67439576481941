export const organisationsConstants = {
  ORGANISATIONS_LOADING: "ORGANISATIONS_LOADING",
  ORGANISATIONS_SUCCESS: "ORGANISATIONS_SUCCESS",
  ORGANISATIONS_FAILURE: "ORGANISATIONS_FAILURE",
};

export const organisationsByIdConstants = {
  ORGANISATIONS_BY_ID_LOADING: "ORGANISATIONS_BY_ID_LOADING",
  ORGANISATIONS_BY_ID_SUCCESS: "ORGANISATIONS_BY_ID_SUCCESS",
  ORGANISATIONS_BY_ID_FAILURE: "ORGANISATIONS_BY_ID_FAILURE",
};

export const organisationsWiseCouncilsConstants = {
  ORGANISATIONS_WISE_COUNCILS_LOADING: "ORGANISATIONS_WISE_COUNCILS_LOADING",
  ORGANISATIONS_WISE_COUNCILS_SUCCESS: "ORGANISATIONS_WISE_COUNCILS_SUCCESS",
  ORGANISATIONS_WISE_COUNCILS_FAILURE: "ORGANISATIONS_WISE_COUNCILS_FAILURE",
};
