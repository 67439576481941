import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import rootReducer from "./reducers";

const middlewares = [thunkMiddleware];
let middleWareFn = null;

if (process.env.NODE_ENV === `development`) {
  const {
    composeWithDevTools,
  } = require("redux-devtools-extension/developmentOnly");
  middleWareFn = composeWithDevTools(applyMiddleware(...middlewares));
} else {
  middleWareFn = applyMiddleware(...middlewares);
}

export const store = createStore(rootReducer, middleWareFn);
