export const allDistrictsConstants = {
  ALL_DISTRICTS_LOADING: "ALL_DISTRICTS_LOADING",
  ALL_DISTRICTS_SUCCESS: "ALL_DISTRICTS_SUCCESS",
  ALL_DISTRICTS_FAILURE: "ALL_DISTRICTS_FAILURE",
};

export const allDistrictsDataConstants = {
  ALL_DISTRICTS_DATA_LOADING: "ALL_DISTRICTS_DATA_LOADING",
  ALL_DISTRICTS_DATA_SUCCESS: "ALL_DISTRICTS_DATA_SUCCESS",
  ALL_DISTRICTS_DATA_FAILURE: "ALL_DISTRICTS_DATA_FAILURE",
};
