import {
  departmentsConstants,
  departmentsByIdConstants,
  departmentWiseCouncilsConstants,
} from "../constants";

export function departmentsReducer(
  state = {
    departmentsLoading: false,
    departmentsData: null,
    departmentsError: null,

    departmentsByIdLoading: false,
    departmentsByIdData: null,
    departmentsByIdError: null,

    departmentsWiseCouncilsLoading: false,
    departmentsWiseCouncilsData: null,
    departmentsWiseCouncilsError: null,
  },
  action
) {
  switch (action.type) {
    case departmentsConstants.DEPARTMENTS_LOADING:
      return Object.assign({}, state, {
        departmentsLoading: true,
        departmentsData: null,
        departmentsError: null,
      });
    case departmentsConstants.DEPARTMENTS_SUCCESS:
      return Object.assign({}, state, {
        departmentsLoading: false,
        departmentsData: action.data,
      });
    case departmentsConstants.DEPARTMENTS_FAILURE:
      return Object.assign({}, state, {
        departmentsLoading: false,
        departmentsData: null,
        departmentsError: action.error,
      });

    case departmentsByIdConstants.DEPARTMENTS_BY_ID_LOADING:
      return Object.assign({}, state, {
        departmentsByIdLoading: true,
        departmentsByIdData: null,
        departmentsByIdError: null,
      });
    case departmentsByIdConstants.DEPARTMENTS_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        departmentsByIdLoading: false,
        departmentsByIdData: action.data,
      });
    case departmentsByIdConstants.DEPARTMENTS_BY_ID_FAILURE:
      return Object.assign({}, state, {
        departmentsByIdLoading: false,
        departmentsByIdData: null,
        departmentsByIdError: action.error,
      });

    case departmentWiseCouncilsConstants.DEPARTMENTS_WISE_COUNCILS_LOADING:
      return Object.assign({}, state, {
        departmentsWiseCouncilsLoading: true,
        departmentsWiseCouncilsData: null,
        departmentsWiseCouncilsError: null,
      });
    case departmentWiseCouncilsConstants.DEPARTMENTS_WISE_COUNCILS_SUCCESS:
      return Object.assign({}, state, {
        departmentsWiseCouncilsLoading: false,
        departmentsWiseCouncilsData: action.data,
      });
    case departmentWiseCouncilsConstants.DEPARTMENTS_WISE_COUNCILS_FAILURE:
      return Object.assign({}, state, {
        departmentsWiseCouncilsLoading: false,
        departmentsWiseCouncilsData: null,
        departmentsWiseCouncilsError: action.error,
      });

    default:
      return state;
  }
}
