import {
  organisationsConstants,
  organisationsByIdConstants,
  organisationsWiseCouncilsConstants,
} from "../constants";

export function organisationsReducer(
  state = {
    organisationsLoading: false,
    organisationsData: null,
    organisationsError: null,

    organisationsByIdLoading: false,
    organisationsByIdData: null,
    organisationsByIdError: null,

    organisationsWiseCouncilsLoading: false,
    organisationsWiseCouncilsData: null,
    organisationsWiseCouncilsError: null,
  },
  action
) {
  switch (action.type) {
    case organisationsConstants.ORGANISATIONS_LOADING:
      return Object.assign({}, state, {
        organisationsLoading: true,
        organisationsData: null,
        organisationsError: null,
      });
    case organisationsConstants.ORGANISATIONS_SUCCESS:
      return Object.assign({}, state, {
        organisationsLoading: false,
        organisationsData: action.data,
      });
    case organisationsConstants.ORGANISATIONS_FAILURE:
      return Object.assign({}, state, {
        organisationsLoading: false,
        organisationsData: null,
        organisationsError: action.error,
      });

    case organisationsByIdConstants.ORGANISATIONS_BY_ID_LOADING:
      return Object.assign({}, state, {
        organisationsByIdLoading: true,
        organisationsByIdData: null,
        organisationsByIdError: null,
      });
    case organisationsByIdConstants.ORGANISATIONS_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        organisationsByIdLoading: false,
        organisationsByIdData: action.data,
      });
    case organisationsByIdConstants.ORGANISATIONS_BY_ID_FAILURE:
      return Object.assign({}, state, {
        organisationsByIdLoading: false,
        organisationsByIdData: null,
        organisationsByIdError: action.error,
      });

    case organisationsWiseCouncilsConstants.ORGANISATIONS_WISE_COUNCILS_LOADING:
      return Object.assign({}, state, {
        organisationsWiseCouncilsLoading: true,
        organisationsWiseCouncilsData: null,
        organisationsWiseCouncilsError: null,
      });
    case organisationsWiseCouncilsConstants.ORGANISATIONS_WISE_COUNCILS_SUCCESS:
      return Object.assign({}, state, {
        organisationsWiseCouncilsLoading: false,
        organisationsWiseCouncilsData: action.data,
      });
    case organisationsWiseCouncilsConstants.ORGANISATIONS_WISE_COUNCILS_FAILURE:
      return Object.assign({}, state, {
        organisationsWiseCouncilsLoading: false,
        organisationsWiseCouncilsData: null,
        organisationsWiseCouncilsError: action.error,
      });

    default:
      return state;
  }
}
