export const institutionsTypeConstants = {
  INSTITUTIONS_TYPE_LOADING: "INSTITUTIONS_TYPE_LOADING",
  INSTITUTIONS_TYPE_SUCCESS: "INSTITUTIONS_TYPE_SUCCESS",
  INSTITUTIONS_TYPE_FAILURE: "INSTITUTIONS_TYPE_FAILURE",
};

export const institutionsByTypeConstants = {
  INSTITUTIONS_LOADING: "INSTITUTIONS_LOADING",
  INSTITUTIONS_SUCCESS: "INSTITUTIONS_SUCCESS",
  INSTITUTIONS_FAILURE: "INSTITUTIONS_FAILURE",
};
