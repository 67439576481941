import { missionsConstants, missionByIdConstants } from "../constants";

export function missionsReducer(
  state = {
    missionsLoading: false,
    missionsData: null,
    missionsError: null,

    missionByIdLoading: false,
    missionByIdData: null,
    missionByIdError: null,
  },
  action
) {
  switch (action.type) {
    case missionsConstants.MISSIONS_LOADING:
      return Object.assign({}, state, {
        missionsLoading: true,
        missionsData: null,
        missionsError: null,
      });
    case missionsConstants.MISSIONS_SUCCESS:
      return Object.assign({}, state, {
        missionsLoading: false,
        missionsData: action.data,
      });
    case missionsConstants.MISSIONS_FAILURE:
      return Object.assign({}, state, {
        missionsLoading: false,
        missionsData: null,
        missionsError: action.error,
      });
    case missionByIdConstants.MISSION_BY_ID_LOADING:
      return Object.assign({}, state, {
        missionByIdLoading: true,
        missionByIdData: null,
        missionByIdError: null,
      });
    case missionByIdConstants.MISSION_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        missionByIdLoading: false,
        missionByIdData: action.data,
      });
    case missionByIdConstants.MISSION_BY_ID_FAILURE:
      return Object.assign({}, state, {
        missionByIdLoading: false,
        missionByIdData: null,
        missionByIdError: action.error,
      });

    default:
      return state;
  }
}
