export const yearConstants = {
  YEAR_LOADING: "YEAR_LOADING",
  YEAR_SUCCESS: "YEAR_SUCCESS",
  YEAR_FAILURE: "YEAR_FAILURE",
};

export const resourcesByYearConstants = {
  RESOURCES_BY_YEAR_LOADING: "RESOURCES_BY_YEAR_LOADING",
  RESOURCES_BY_YEAR_SUCCESS: "RESOURCES_BY_YEAR_SUCCESS",
  RESOURCES_BY_YEAR_FAILURE: "RESOURCES_BY_YEAR_FAILURE",
};
