import {
  institutionsTypeConstants,
  institutionsByTypeConstants,
} from "../constants";

export function institutionsReducer(
  state = {
    institutionsTypeLoading: false,
    institutionsTypeData: null,
    institutionsTypeError: null,

    institutionsByTypeLoading: false,
    institutionsByTypeData: null,
    institutionsByTypeError: null,
  },
  action
) {
  switch (action.type) {
    case institutionsTypeConstants.INSTITUTIONS_TYPE_LOADING:
      return Object.assign({}, state, {
        institutionsTypeLoading: true,
        institutionsTypeData: null,
        institutionsTypeError: null,
      });
    case institutionsTypeConstants.INSTITUTIONS_TYPE_SUCCESS:
      return Object.assign({}, state, {
        institutionsTypeLoading: false,
        institutionsTypeData: action.data,
      });
    case institutionsTypeConstants.INSTITUTIONS_TYPE_FAILURE:
      return Object.assign({}, state, {
        institutionsTypeLoading: false,
        institutionsTypeData: null,
        institutionsTypeError: action.error,
      });
    case institutionsByTypeConstants.INSTITUTIONS_LOADING:
      return Object.assign({}, state, {
        institutionsByTypeLoading: true,
        institutionsByTypeData: null,
        institutionsByTypeError: null,
      });
    case institutionsByTypeConstants.INSTITUTIONS_SUCCESS:
      return Object.assign({}, state, {
        institutionsByTypeLoading: false,
        institutionsByTypeData: action.data,
      });
    case institutionsByTypeConstants.INSTITUTIONS_FAILURE:
      return Object.assign({}, state, {
        institutionsByTypeLoading: false,
        institutionsByTypeData: null,
        institutionsByTypeError: action.error,
      });
    default:
      return state;
  }
}
