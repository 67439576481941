import { resourcesByYearConstants, yearConstants } from "../constants";

export function resourcesReducer(
  state = {
    yearLoading: false,
    yearData: null,
    yearError: null,

    resourceByYearLoading: false,
    resourceByYearData: null,
    resourceByYearError: null,
  },
  action
) {
  switch (action.type) {
    case yearConstants.YEAR_LOADING:
      return Object.assign({}, state, {
        yearLoading: true,
        yearData: null,
        yearError: null,
      });
    case yearConstants.YEAR_SUCCESS:
      return Object.assign({}, state, {
        yearLoading: false,
        yearData: action.data,
      });
    case yearConstants.YEAR_FAILURE:
      return Object.assign({}, state, {
        yearLoading: false,
        yearData: null,
        yearError: action.error,
      });

    case resourcesByYearConstants.RESOURCES_BY_YEAR_LOADING:
      return Object.assign({}, state, {
        resourceByYearLoading: true,
        resourceByYearData: null,
        resourceByYearError: null,
      });
    case resourcesByYearConstants.RESOURCES_BY_YEAR_SUCCESS:
      return Object.assign({}, state, {
        resourceByYearLoading: false,
        resourceByYearData: action.data,
      });
    case resourcesByYearConstants.RESOURCES_BY_YEAR_FAILURE:
      return Object.assign({}, state, {
        resourceByYearLoading: false,
        resourceByYearData: null,
        resourceByYearError: action.error,
      });
    default:
      return state;
  }
}
