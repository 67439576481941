import { configConstants, menuConstants } from "../constants";

export function configReducer(
  state = {
    configLoading: false,
    configData: null,
    configError: null,

    menuLoading: false,
    menuData: null,
    menuError: null,
  },
  action
) {
  switch (action.type) {
    case configConstants.CONFIG_LOADING:
      return Object.assign({}, state, {
        configLoading: true,
        configData: null,
        configError: null,
      });
    case configConstants.CONFIG_SUCCESS:
      return Object.assign({}, state, {
        configLoading: false,
        configData: action.data,
      });
    case configConstants.CONFIG_FAILURE:
      return Object.assign({}, state, {
        configLoading: false,
        configData: null,
        configError: action.error,
      });

    case menuConstants.MENU_LOADING:
      return Object.assign({}, state, {
        menuLoading: true,
        menuData: null,
        menuError: null,
      });
    case menuConstants.MENU_SUCCESS:
      return Object.assign({}, state, {
        menuLoading: false,
        menuData: action.data,
      });
    case menuConstants.MENU_FAILURE:
      return Object.assign({}, state, {
        menuLoading: false,
        menuData: null,
        menuError: action.error,
      });

    default:
      return state;
  }
}
