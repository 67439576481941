export const missionsConstants = {
  MISSIONS_LOADING: "MISSIONS_LOADING",
  MISSIONS_SUCCESS: "MISSIONS_SUCCESS",
  MISSIONS_FAILURE: "MISSIONS_FAILURE",
};

export const missionByIdConstants = {
  MISSION_BY_ID_LOADING: "MISSION_BY_ID_LOADING",
  MISSION_BY_ID_SUCCESS: "MISSION_BY_ID_SUCCESS",
  MISSION_BY_ID_FAILURE: "MISSION_BY_ID_FAILURE",
};
