export const configConstants = {
  CONFIG_LOADING: "CONFIG_LOADING",
  CONFIG_SUCCESS: "CONFIG_SUCCESS",
  CONFIG_FAILURE: "CONFIG_FAILURE",
};

export const menuConstants = {
  MENU_LOADING: "MENU_LOADING",
  MENU_SUCCESS: "MENU_SUCCESS",
  MENU_FAILURE: "MENU_FAILURE",
};
