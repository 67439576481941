import { boardsConstants, boardByIdConstants } from "../constants";

export function boardsReducer(
  state = {
    boardsLoading: false,
    boardsData: null,
    boardsError: null,

    boardByIdLoading: false,
    boardByIdData: null,
    boardByIdError: null,
  },
  action
) {
  switch (action.type) {
    case boardsConstants.BOARDS_LOADING:
      return Object.assign({}, state, {
        boardsLoading: true,
        boardsData: null,
        boardsError: null,
      });
    case boardsConstants.BOARDS_SUCCESS:
      return Object.assign({}, state, {
        boardsLoading: false,
        boardsData: action.data,
      });
    case boardsConstants.BOARDS_FAILURE:
      return Object.assign({}, state, {
        boardsLoading: false,
        boardsData: null,
        boardsError: action.error,
      });

    case boardByIdConstants.BOARD_BY_ID_LOADING:
      return Object.assign({}, state, {
        boardByIdLoading: true,
        boardByIdData: null,
        boardByIdError: null,
      });
    case boardByIdConstants.BOARD_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        boardByIdLoading: false,
        boardByIdData: action.data,
      });
    case boardByIdConstants.BOARD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        boardByIdLoading: false,
        boardByIdData: null,
        boardByIdError: action.error,
      });
    default:
      return state;
  }
}
