import { homePageConstants } from "../constants";

export function homeReducer(
  state = {
    homePageLoading: false,
    homePageData: null,
    homePageError: null,
  },
  action
) {
  switch (action.type) {
    case homePageConstants.HOME_PAGE_LOADING:
      return Object.assign({}, state, {
        homePageLoading: true,
        homePageData: null,
        homePageError: null,
      });
    case homePageConstants.HOME_PAGE_SUCCESS:
      return Object.assign({}, state, {
        homePageLoading: false,
        homePageData: action.data,
      });
    case homePageConstants.HOME_PAGE_FAILURE:
      return Object.assign({}, state, {
        homePageLoading: false,
        homePageData: null,
        homePageError: action.error,
      });

    default:
      return state;
  }
}
