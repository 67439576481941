export const boardsConstants = {
  BOARDS_LOADING: "BOARDS_LOADING",
  BOARDS_SUCCESS: "BOARDS_SUCCESS",
  BOARDS_FAILURE: "BOARDS_FAILURE",
};

export const boardByIdConstants = {
  BOARD_BY_ID_LOADING: "BOARD_BY_ID_LOADING",
  BOARD_BY_ID_SUCCESS: "BOARD_BY_ID_SUCCESS",
  BOARD_BY_ID_FAILURE: "BOARD_BY_ID_FAILURE",
};
