export const departmentsConstants = {
  DEPARTMENTS_LOADING: "DEPARTMENTS_LOADING",
  DEPARTMENTS_SUCCESS: "DEPARTMENTS_SUCCESS",
  DEPARTMENTS_FAILURE: "DEPARTMENTS_FAILURE",
};

export const departmentsByIdConstants = {
  DEPARTMENTS_BY_ID_LOADING: "DEPARTMENTS_BY_ID_LOADING",
  DEPARTMENTS_BY_ID_SUCCESS: "DEPARTMENTS_BY_ID_SUCCESS",
  DEPARTMENTS_BY_ID_FAILURE: "DEPARTMENTS_BY_ID_FAILURE",
};

export const departmentWiseCouncilsConstants = {
  DEPARTMENTS_WISE_COUNCILS_LOADING: "DEPARTMENTS_WISE_COUNCILS_LOADING",
  DEPARTMENTS_WISE_COUNCILS_SUCCESS: "DEPARTMENTS_WISE_COUNCILS_SUCCESS",
  DEPARTMENTS_WISE_COUNCILS_FAILURE: "DEPARTMENTS_WISE_COUNCILS_FAILURE",
};
