exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-active-clergy-js": () => import("./../../../src/pages/about/active-clergy.js" /* webpackChunkName: "component---src-pages-about-active-clergy-js" */),
  "component---src-pages-about-bishop-js": () => import("./../../../src/pages/about/bishop.js" /* webpackChunkName: "component---src-pages-about-bishop-js" */),
  "component---src-pages-about-bishop-office-js": () => import("./../../../src/pages/about/bishop-office.js" /* webpackChunkName: "component---src-pages-about-bishop-office-js" */),
  "component---src-pages-about-church-workers-js": () => import("./../../../src/pages/about/church-workers.js" /* webpackChunkName: "component---src-pages-about-church-workers-js" */),
  "component---src-pages-about-church-workers-retired-js": () => import("./../../../src/pages/about/church-workers-retired.js" /* webpackChunkName: "component---src-pages-about-church-workers-retired-js" */),
  "component---src-pages-about-coporate-office-js": () => import("./../../../src/pages/about/coporate-office.js" /* webpackChunkName: "component---src-pages-about-coporate-office-js" */),
  "component---src-pages-about-council-members-js": () => import("./../../../src/pages/about/council-members.js" /* webpackChunkName: "component---src-pages-about-council-members-js" */),
  "component---src-pages-about-csi-logo-motto-js": () => import("./../../../src/pages/about/csi-logo-motto.js" /* webpackChunkName: "component---src-pages-about-csi-logo-motto-js" */),
  "component---src-pages-about-csi-mkd-js": () => import("./../../../src/pages/about/csi-mkd.js" /* webpackChunkName: "component---src-pages-about-csi-mkd-js" */),
  "component---src-pages-about-diocesan-logo-motto-js": () => import("./../../../src/pages/about/diocesan-logo-motto.js" /* webpackChunkName: "component---src-pages-about-diocesan-logo-motto-js" */),
  "component---src-pages-about-executive-committee-js": () => import("./../../../src/pages/about/executive-committee.js" /* webpackChunkName: "component---src-pages-about-executive-committee-js" */),
  "component---src-pages-about-fcra-js": () => import("./../../../src/pages/about/fcra.js" /* webpackChunkName: "component---src-pages-about-fcra-js" */),
  "component---src-pages-about-former-bishops-js": () => import("./../../../src/pages/about/former-bishops.js" /* webpackChunkName: "component---src-pages-about-former-bishops-js" */),
  "component---src-pages-about-liturgical-colours-js": () => import("./../../../src/pages/about/liturgical-colours.js" /* webpackChunkName: "component---src-pages-about-liturgical-colours-js" */),
  "component---src-pages-about-retired-clergies-js": () => import("./../../../src/pages/about/retired-clergies.js" /* webpackChunkName: "component---src-pages-about-retired-clergies-js" */),
  "component---src-pages-about-sisters-js": () => import("./../../../src/pages/about/sisters.js" /* webpackChunkName: "component---src-pages-about-sisters-js" */),
  "component---src-pages-about-sisters-retired-js": () => import("./../../../src/pages/about/sisters-retired.js" /* webpackChunkName: "component---src-pages-about-sisters-retired-js" */),
  "component---src-pages-almanac-js": () => import("./../../../src/pages/almanac.js" /* webpackChunkName: "component---src-pages-almanac-js" */),
  "component---src-pages-announcement-detail-[uuid]-js": () => import("./../../../src/pages/announcement-detail/[uuid].js" /* webpackChunkName: "component---src-pages-announcement-detail-[uuid]-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-boards-[slug]-js": () => import("./../../../src/pages/boards/[slug].js" /* webpackChunkName: "component---src-pages-boards-[slug]-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-daily-bible-js": () => import("./../../../src/pages/daily-bible.js" /* webpackChunkName: "component---src-pages-daily-bible-js" */),
  "component---src-pages-departments-[slug]-js": () => import("./../../../src/pages/departments/[slug].js" /* webpackChunkName: "component---src-pages-departments-[slug]-js" */),
  "component---src-pages-districts-[slug]-js": () => import("./../../../src/pages/districts/[slug].js" /* webpackChunkName: "component---src-pages-districts-[slug]-js" */),
  "component---src-pages-event-detail-[uuid]-js": () => import("./../../../src/pages/event-detail/[uuid].js" /* webpackChunkName: "component---src-pages-event-detail-[uuid]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institutions-[slug]-js": () => import("./../../../src/pages/institutions/[slug].js" /* webpackChunkName: "component---src-pages-institutions-[slug]-js" */),
  "component---src-pages-mission-fields-[slug]-js": () => import("./../../../src/pages/mission-fields/[slug].js" /* webpackChunkName: "component---src-pages-mission-fields-[slug]-js" */),
  "component---src-pages-more-news-js": () => import("./../../../src/pages/more-news.js" /* webpackChunkName: "component---src-pages-more-news-js" */),
  "component---src-pages-news-detail-[uuid]-js": () => import("./../../../src/pages/news-detail/[uuid].js" /* webpackChunkName: "component---src-pages-news-detail-[uuid]-js" */),
  "component---src-pages-organisations-[slug]-js": () => import("./../../../src/pages/organisations/[slug].js" /* webpackChunkName: "component---src-pages-organisations-[slug]-js" */),
  "component---src-pages-people-[uuid]-js": () => import("./../../../src/pages/people/[uuid].js" /* webpackChunkName: "component---src-pages-people-[uuid]-js" */),
  "component---src-pages-people-group-[slug]-js": () => import("./../../../src/pages/people/group/[slug].js" /* webpackChunkName: "component---src-pages-people-group-[slug]-js" */),
  "component---src-pages-prayer-calendar-js": () => import("./../../../src/pages/prayer-calendar.js" /* webpackChunkName: "component---src-pages-prayer-calendar-js" */),
  "component---src-pages-publication-detail-[uuid]-js": () => import("./../../../src/pages/publication-detail/[uuid].js" /* webpackChunkName: "component---src-pages-publication-detail-[uuid]-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-resources-[year]-js": () => import("./../../../src/pages/resources/[year].js" /* webpackChunkName: "component---src-pages-resources-[year]-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sermon-notes-js": () => import("./../../../src/pages/sermon-notes.js" /* webpackChunkName: "component---src-pages-sermon-notes-js" */),
  "component---src-pages-upcoming-events-js": () => import("./../../../src/pages/upcoming-events.js" /* webpackChunkName: "component---src-pages-upcoming-events-js" */),
  "component---src-pages-worship-resources-js": () => import("./../../../src/pages/worship-resources.js" /* webpackChunkName: "component---src-pages-worship-resources-js" */)
}

