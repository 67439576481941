import { combineReducers } from "redux";

import { homeReducer } from "./home.reducer";
import { boardsReducer } from "./boards.reducer";
import { organisationsReducer } from "./organisations.reducer";
import { departmentsReducer } from "./departments.reducer";
import { missionsReducer } from "./missions.reducer";
import { institutionsReducer } from "./institutions.reducer";
import { districtsReducer } from "./districts.reducer";
import { configReducer } from "./config.reducer";
import { resourcesReducer } from "./resources.reducer";

const rootReducer = combineReducers({
  homeReducer,
  boardsReducer,
  organisationsReducer,
  departmentsReducer,
  missionsReducer,
  institutionsReducer,
  districtsReducer,
  configReducer,
  resourcesReducer,
});

export default rootReducer;
